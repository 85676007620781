<template>
  <transition>
    <router-view></router-view>
  </transition>
</template>
<script>
export default {
  mounted() {
    let con = this.$route.params.console
    if (!con) {
      con = 'PSN'
    } else {
      con = con.toUpperCase()
    }
    if (window.consoles.indexOf(con) >= 0) {
      this.$store.dispatch('SET_CONSOLE', con.toLowerCase())
      localStorage.setItem('console', con.toLowerCase())
    } else {
      this.$store.dispatch('SET_CONSOLE', 'psn')
      localStorage.setItem('console', 'psn')
    }
  }
}
</script>
